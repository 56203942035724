<template>
  <div>
    <pro-document-breadcrumbs :breadcrumbs="breadcrumbs" user-role="procurer" />
    <div class="q-pa-md">
      <pro-form-render
        mode="Edit"
        :code="workflowCode"
        :form-data-promise="formDataPromise"
        @pro-form-submit="
          () => this.$router.push({ name: 'SupplierManagementList' })
        "
      />
    </div>
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import ProFormRender from "@/components/PROSmart/FormBuilder/ProFormRender";

export default {
  components: { ProDocumentBreadcrumbs, ProFormRender },
  data() {
    return {
      breadcrumbs: [
        {
          name: "menu.SupplierManagement",
          icon: "PROSmart-Account",
          to: { name: "SupplierManagementList" },
        },
        { name: "menu.EditSupplierInfo" },
      ],
      workflowCode: "P_EditSupplierInfo",
      formDataPromise: null,
    };
  },
  created() {
    const { userId } = this.$route.params;
    this.formDataPromise = Promise.all([
      this.$proSmart.supplierUi.getSupplierBasicInfoByProcurer(this, userId),
      this.$proSmart.supplierUi.getSupplierCompanyInfoByProcurer(this, userId),
    ]).then(([basicInfo, companyInfo]) => ({
      ...basicInfo,
      ...companyInfo,
      userId: userId,
    }));
  },
};
</script>
